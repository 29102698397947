import { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import classNames from "classnames"
import ReactOtpInput from "react-otp-input"
import Timer from "timer.js"
import tw, { styled } from "twin.macro"

import ConditionalWrapper from "@components/ConditionalWrapper"
import { Else, If, Then, When } from "@components/If"
import Link from "@components/Link"
import { LOST_PHONE_NUMBER } from "@const/moengage-analytic-event"
import Space from "@layouts/Space"

interface GroupProps {
    block?: boolean
}

const Group = styled.div<GroupProps>`
    ${tw`flex flex-col w-fit`}

    ${({ block }) => block && tw`w-full`}
`

const Label = tw.span`
    title-4 text-main dark:text-dark-main
`

const LabelSuffix = tw.div``

const Error = tw.span`
    title-5 text-error dark:text-dark-error
    mt-1
`

const ResendButton = tw.button`
    bg-foreground-teal dark:bg-dark-foreground-teal
    title-5 text-primary dark:text-dark-primary
    py-1 px-2.5 rounded-full

    disabled:bg-action-disabled dark:disabled:bg-dark-action-disabled
    disabled:text-disabled dark:disabled:text-dark-disabled
`

export interface InputVerificationProps {
    /**
     * Type of verification
     * @default sms
     *
     */
    type?: "sms" | "authenticator" | "email"
    /**
     * Label
     *
     */
    label?: string | React.ReactNode
    /**
     *  Label suffix
     *
     */
    labelSuffix?: string | React.ReactNode
    /**
     * Error message
     *
     */
    error?: string
    /**
     * Set onChange event
     * @param value
     *
     */
    onChange?: (value: string) => void
    /**
     * Set onFinish input event
     * @param value
     *
     */
    onFinish?: (value: string) => void
    /**
     * Set event onClick resend button
     *
     */
    onResend?: () => void
    /**
     * Set width separator
     * @default 8
     *
     */
    separatorWidth?: number
    /**
     * Set time count down after click resend button
     * @default 120 (seconds)
     *
     */
    count?: number
    /**
     * Set class name group
     *
     */
    groupClassName?: string
    /**
     * Set error class name
     *
     */
    errorClassName?: string
    /**
     * Set class name otp
     *
     */
    otpClassName?: string
    /**
     * Set auto focus input on render
     * @default false
     *
     */
    shouldAutoFocus?: boolean
    /**
     * Set disabled input
     * @default false
     *
     */
    disabled?: boolean
    /**
     * size of input
     * @default sm
     *
     */
    size?: "sm" | "md"
    /**
     * Group ref of input
     */
    groupRef?: React.MutableRefObject<HTMLDivElement>
    /**
     * Set block input
     * @default false
     */
    block?: boolean
    /**
     * Set with recovery phone link
     * @default true
     */
    withRecoveryPhoneLink?: boolean
    /**
     * Set initial count start
     * @default false
     */
    initialCountStart?: boolean
    /**
     * handle counting with props
     *
     */
    counting?: boolean
    /**
     * callback to get isCountDown state from outside
     *
     */
    getIsCountDown?: (isCountDown: boolean) => void
    /**
     * source event lostphone
     *
     */
    source?: string
}

const InputVerification: React.FC<InputVerificationProps> = ({
    type,
    label,
    labelSuffix,
    error,
    onChange,
    onFinish,
    onResend,
    separatorWidth,
    disabled,
    count = 120,
    errorClassName,
    shouldAutoFocus,
    size,
    groupRef,
    block,
    withRecoveryPhoneLink = false,
    initialCountStart = false,
    groupClassName,
    otpClassName,
    counting,
    getIsCountDown,
    source
}: InputVerificationProps) => {
    const { t } = useTranslation("components")

    const [value, setValue] = useState<string>("")
    const [isCount, setIsCount] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [counter, setCounter] = useState<number>(count)

    const inputSpacing = size === "sm" ? 11.2 : 8

    const countDown = new Timer({
        tick: 1,
        onstart() {
            setIsCount(true)
            setCounter(counter - 1)
        },
        ontick() {
            setCounter((prevCounter) => prevCounter - 1)
        },
        onend() {
            setCounter(count)
            setIsCount(false)
        }
    })

    useEffect(() => {
        if (initialCountStart) {
            setIsFirst(false)
            countDown.start(count)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (val: string) => {
        setValue(val)
        onChange?.(val)

        if (val.length === 6) {
            setSuccess(true)
            onFinish?.(val)
        } else {
            setSuccess(false)
        }
    }

    const handleResendClick = () => {
        setIsFirst(false)
        countDown.start(count)
        onResend?.()
    }

    useEffect(() => {
        if (counting) {
            setIsFirst(false)
            countDown.start(count)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counting])

    useEffect(() => {
        getIsCountDown?.(isCount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCount])

    return (
        <Group ref={groupRef} block={block} className={classNames("reku-new", groupClassName)}>
            <When condition={label || type === "authenticator"}>
                <Space
                    align='center'
                    justify='between'
                    className={classNames("w-full", size === "sm" ? "mb-3" : "mb-4")}
                >
                    <Label>{label || t("otp.input_authenticator_label")}</Label>
                    <When condition={labelSuffix}>
                        <LabelSuffix>{labelSuffix}</LabelSuffix>
                    </When>
                </Space>
            </When>
            <ReactOtpInput
                numInputs={6}
                value={value}
                inputStyle={classNames(
                    "border border-main dark:border-dark-main rounded-[8px] bg-transparent font-bold",
                    {
                        "!border-main dark:!border-dark-main bg-background-strong dark:bg-dark-background-strong text-disabled dark:text-dark-disabled":
                            disabled,
                        "border-focus dark:border-dark-focus": success,
                        "border-error dark:border-dark-error": error,
                        "!w-12 !h-12": size === "md",
                        "!w-10 !h-10": size === "sm"
                    }
                )}
                focusStyle='focus:border-focus dark:focus:border-dark-focus outline-none'
                errorStyle='!border-error dark:!border-dark-error focus:!border-error focus:dark:!border-dark-error'
                isInputNum
                hasErrored={!!error}
                separator={<span style={{ width: `${separatorWidth || inputSpacing}px` }} />}
                isDisabled={disabled}
                shouldAutoFocus={shouldAutoFocus}
                onChange={handleChange}
                containerStyle={classNames("flex justify-between w-full", otpClassName)}
            />
            <When condition={error}>
                <Error className={errorClassName}>{error}</Error>
            </When>
            <When condition={type === "sms" || type === "email"}>
                <Space align='center' justify='between' className={classNames("w-full", error ? "mt-3" : "mt-[28px]")}>
                    <ConditionalWrapper
                        condition={withRecoveryPhoneLink}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        wrapper={(children) => (
                            <Link
                                onClick={async () => {
                                    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                                    moengageAnalytic.track(LOST_PHONE_NUMBER, {
                                        source
                                    })
                                }}
                                href='/recoveryphone'
                            >
                                {children}
                            </Link>
                        )}
                    >
                        <span className='paragraph-4 text-additional dark:text-dark-additional'>
                            {t("otp.not_get")}
                        </span>
                    </ConditionalWrapper>

                    <If condition={isFirst}>
                        <Then>
                            <ResendButton onClick={handleResendClick} disabled={disabled}>
                                {t("otp_input.sms.send_otp")}
                            </ResendButton>
                        </Then>
                        <Else>
                            <ResendButton onClick={handleResendClick} disabled={isCount || disabled}>
                                <When condition={isCount}>
                                    (
                                    <When condition={count > 60}>
                                        {Math.floor(counter / 60)
                                            .toString()
                                            .padStart(2, "0")}
                                        :
                                    </When>
                                    {(counter % 60).toString().padStart(2, "0")}){" "}
                                </When>
                                {t("otp.resend")}
                            </ResendButton>
                        </Else>
                    </If>
                </Space>
                <When condition={type === "sms"}>
                    <Link
                        onClick={async () => {
                            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                            if (source) {
                                moengageAnalytic.track(LOST_PHONE_NUMBER, {
                                    source
                                })
                            }
                        }}
                        href='/recoveryphone'
                        className='text-action-secondary mt-4 dark:text-dark-action-secondary button-2 mx-auto'
                    >
                        {t("components:otp_input.lost_phone")}
                    </Link>
                </When>
            </When>
        </Group>
    )
}
InputVerification.defaultProps = {
    type: "sms",
    label: undefined,
    labelSuffix: undefined,
    error: undefined,
    errorClassName: undefined,
    groupClassName: undefined,
    otpClassName: undefined,
    separatorWidth: 8,
    count: 120,
    shouldAutoFocus: false,
    disabled: false,
    size: "sm",
    groupRef: undefined,
    onChange: undefined,
    onFinish: undefined,
    onResend: undefined,
    block: false,
    withRecoveryPhoneLink: false,
    initialCountStart: false,
    counting: false,
    getIsCountDown: undefined
}

export default InputVerification
